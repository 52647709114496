// Configuration settings for Multi-tenant Staging (Non-Prod / Pre-Prod)
export const environment = {
    APP_VERSION_MAJOR: 2,
    APP_VERSION_MINOR: 7,
    APP_VERSION_PATCH: 0,

    PRODUCTION: false,
    STAGING: true,

    NODE_BASE_URL: 'https://api-node.staging.atc.engincloud.com',
    NODE_API_URL: 'https://api-node.staging.atc.engincloud.com/api',
    PYTHON_API_URL: '',

    OAUTH_CLIENT_ID: '1BVDn1pM8MtUiAQ6s4R7gc3WIjtV7kQsfz4Ex7aM',
    OAUTH_CLIENT_SECRET: 'fMm3BWoTihmrWAb5u0qa8mtFbh9k0PARXFLKAYQh',

    SSO_ENABLED: true,
    AZURE_AD: {
        AUTHORITY: 'https://login.microsoftonline.com/organizations/',
        APPLICATION_ID: '128e7df0-26a8-4b86-a7b2-5615b3f58e81',
        REDIRECT_URL: 'https://staging.atc.engincloud.com',
    },
    SENSITIVE_INFO_LOGIN: true,
    SENSITIVE_INFO_HEADER: true,
};
